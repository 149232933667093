import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/chromelp2step.module.scss"
import Data from "./data/chromelp2step.json"
import Footerlinks from "@tightrope/footerlinks/footer"


class chromelp extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        isChrome: isChrome(),
        isFirefox: isFirefox(),
        isIeEdge: isIeEdge(),
        display: 'none',
        display2: 'none',
        pageData: props.data,
        data: Data
      }
    }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  clickCta() {
    if(typeof window !== 'undefined'){
      document.getElementsByClassName('arrow-overlay')[0].style.background = 'none';
        document.getElementsByClassName('arrow-overlay')[0].style.pointerEvents = 'none';
      window.triggerInstall();
      document.getElementsByClassName('btf_cb-module--btfCb')[0].style.display = 'none';
      //dl2link
         let currentBrowser;
            //browser detection
            if ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) && (navigator.userAgent.toLowerCase().indexOf("edg") == -1)) {
                currentBrowser = "ch";
            }

            else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                currentBrowser = "ff";
            }

            else if (navigator.userAgent.toLowerCase().indexOf("edg") > -1) {
                currentBrowser = "ed";
            }

            else if (navigator.userAgent.toLowerCase().indexOf("trident/") > -1
                || navigator.userAgent.toLowerCase().indexOf("msie") > -1) {
                currentBrowser = "ie";
            }
            else {
                currentBrowser = null;
            }

        if(document.getElementsByClassName('dl2link') && window.site_config) {
            let dl2link = document.getElementsByClassName('dl2link')[0];
            if (dl2link) dl2link.href = window.site_config.clearbar_config.exe_link + "&version=" + (window.site_config.clearbar_config.version || '') + "&feature=" + (window.site_config.clearbar_config.feature || '') + "&brand=" + (window.site_config.clearbar_config.brand || '') + "&browser=" + currentBrowser;
        }

          }
    this.setState({display: 'none', display2: 'flex'});
  }




    componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    if(this.props.data){
      this.props.data.then(data => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }

      });
    }
  }
  render() {
    return (
      <>
      <div id = {Styles.chromelp} style={{ display: this.state.display, height: '100vh' }}>
        <div className = {Styles.header}><img src="/img/clear.png" alt="" /></div>
     <div className = {Styles.main}>
       <h1 className = "hc_param">{this.state.data.headline}</h1>

       <div className = {Styles.stepGrid}>
         <div className = {Styles.step}>1</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead1}</h2>
           <p>{this.state.data.copy1}</p>
         </div>

         <div className = {Styles.step}>2</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead2}</h2>
           <p>{this.state.data.copy2}</p>
         </div>
       </div>
       <div className = {Styles.comp}>{this.state.data.compliance}</div>
       <button className = {Styles.download} data-cy="cta" onClick = {() => this.clickCta()}>{this.state.data.button}</button>

     <div className = {Styles.disclosure}>By clicking above you agree to the <a href="https://clearbar.app/terms-of-service" target="_blank">Terms of Service</a> and <a href="https://clearbar.app/privacy-policy" target="_blank">Privacy Policy</a> for Clear, a Chromium based browser with web search results provided by Yahoo.</div>
       </div>
        </div>
      <Footerlinks></Footerlinks>
      <div class="troubleDL" style={{ display: this.state.display2 }}>
        <h1>Thanks for Downloading!<span>
          If your download does not automatically start, <a class="dl2link" href="">click here to try again.</a></span></h1>
      </div>
      </>
    )
  }
}
export default chromelp
